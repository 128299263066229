import React, { useEffect, useState } from 'react';
import styles from './RegistrationStep3.module.css'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isJwtExpired } from "jwt-check-expiration";
import { DeviceUUID } from "device-uuid";
import HelpIcon from "../HelpIcon/HelpIcon";
import { getOtpCode } from "../FetchServises/GetOtpCodeService";
import arrow from "../../../img/arrow-sm-right.svg";


const RegistrationStep3 = (props) => {

    const config = require('../../../settings/config.js');

    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(59);
    const [badAttempt, setBadAttempt] = useState(0);
    let securePhoneNumber = '';

    if (localStorage.getItem('phone')) {
        securePhoneNumber = formatPhoneNumber(localStorage.getItem('fullPhone'));
    }

    function formatPhoneNumber(phone) {
        return phone.replace(
            /^\+?(\d{1,3})\s*\(?(\d{2,3})\)?[-\s]*(\d{3})[-\s]*(\d{2})[-\s]*(\d{2})$/,
            (match, countryCode, group1, group2, group3, group4) => {
                // Заменяем цифры на X в зависимости от длины групп
                const maskedGroup1 = 'X'.repeat(group1.length);
                const maskedGroup2 = 'X'.repeat(group2.length);
                return `+${countryCode} ${maskedGroup1} ${maskedGroup2} ${group3} ${group4}`;
            }
        );
    }

    const navigate = useNavigate();

    function handlePhoneChange() {
        navigate('/step2');
    }

    function handleOtpChange(otp) {
        setOtp(otp);
    }

    useEffect(() => {
        counter > 0 && setTimeout(function timer() {
            if (counter < 11) {
                setCounter((counter - 1).toString().padStart(2, '0'));
            } else {
                //
                setCounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    useEffect(() => {
        async function authHandle() {
            let uuid = new DeviceUUID().get();
            const authRequest = {
                username: localStorage.getItem('phone').replace(/\D/g, ''),
                password: otp,
                deviceId: uuid
            }

            try {
                const response = await fetch(`${config.app.host}/api/v1/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(authRequest),
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        setBadAttempt(badAttempt + 1);
                        if (badAttempt >= 5) {
                            // TODO: вызвать на бэке метод для отмены авторизации
                            toast.error('Слишком много неудачных попыток ', {
                                position: "top-center",
                                autoClose: 3000
                            });
                            navigate('/step2')
                            return;
                        }
                    }
                    toast.error('Неверный код ', {
                        position: "top-center",
                        autoClose: 3000
                    });
                    setOtp('');
                    return;
                    // forceUpdate();
                }

                const token = await response.json();
                localStorage.setItem('m_token', token.token);
                console.log('isExpired is:', isJwtExpired(token.token));
                toast.success('Авторизация прошла успешно', {
                    position: "top-center",
                    autoClose: 1000
                });
                if (localStorage.getItem('pin')) {
                    navigate('/pin');
                    return;
                }
                navigate('/pinoffer');

            } catch (error) {
                toast.error('Ошибка nen:' + error.message, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        }

        if (otp.length === 4) {
            authHandle();
        }

    }, [otp]);

    async function requestSMSRepeat() {
        if (counter !== "00") {
            return;
        }
        let resp = '';
        try {
            resp = await getOtpCode(localStorage.getItem('phone').replace(/\D/g, ''))
        } catch (error) {
            console.log(error.message);
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000
            });
            return;
        }
        let pass = "";
        if (resp.showInToast === "yes") {
            pass = resp.token;
        }
        // End request SMS code
        toast.success(`Вам отправлен SMS-код ${pass}`, {
            position: "top-center",
            autoClose: 3000
        });

        setCounter(59);
    }

    const handleBack = () => {
        navigate('/step2');
    };
    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>
                    Введите код
                </p>
            </div>
            <div className={styles.section}>
                <div className={styles.textPhoneDiv}>На ваш номер поступит СМС <br />с 4-х
                    значным кодом
                </div>
                <div className={styles.phoneNumber}>
                    <span>{securePhoneNumber} </span>
                    <span
                        className={styles.changePhoneNumber}
                        onClick={handlePhoneChange}
                    >&nbsp;&nbsp;&nbsp;Изменить</span>
                </div>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0}
                    />}
                    containerStyle={styles.otpInlineInput}
                />
                <div className={styles.question}>
                    <span>00:{counter} Сообщение не пришло?</span>
                    <span
                        className={(counter == 0) ? styles.changePhoneNumber : styles.changePhoneNumberDisabled}
                        onClick={requestSMSRepeat}
                    >&nbsp;&nbsp;&nbsp;Повторить</span>
                </div>
            </div>
            <div className={styles.sectionButtons}>
                <div className={styles.button}
                    onClick={handleBack}>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={styles.arrow}
                    />
                    <p className={styles.buttonText}>Назад</p>
                </div>
            </div>
            {/* <div className={styles.sectionButtons}>
                <div className={`${styles.backButton} ${styles.buttons}`} onClick={handleBack}>
                </div>
            </div> */}
        </div>
    );
}
    ;

export default RegistrationStep3;
