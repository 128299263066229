import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {fetchProfile} from "./profileService";
import {checkToken} from "../../Utils/TokenService";

const CheckProfile = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');

    // Чекаем используемые токены в первую очередь
    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            const profile = await fetchProfile(token);
            if (profile) { // Если есть профиль,
                if (!profile.name) { //но нет имени
                    navigate('/regform');
                } else {
                    localStorage.setItem("avatar", profile.avatar);
                    localStorage.setItem("name", profile.name);
                    navigate('/myStations', {state: {name: profile.name}})
                }
            } else {
                // TODO тут ошибка подумать что делать, куда отправлять пользовтеля
                console.log("Профиль не найден");
            }
        }
        fetchData();
    }, []);

    return (
        <></>
    );
}

export default CheckProfile;
