import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
/*import defaultAvatar from '../../img/ava-ufo.png'; // Import the default avatar*/
// Test New Ava
// import defaultAvatar from '../../img/avatars/hzkto.jpg'; // Import the default avatar
import defaultAvatar from '../../img/avatars/nAva.png'; // Import the default avatar
import backArrow from '../../img/avatars/backArrow.svg';
import profile from '../../img/avatars/profile.svg';
import chat from '../../img/avatars/chatMsg.svg';

const Header = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Получаем текущий путь
    const avatarUrl = localStorage.getItem('avatar') || defaultAvatar;

    const handleBack = () => {
        /*navigate(-1);*/ // Возвращаемся на предыдущую страницу
        switch (location.pathname) {
            case '/postreg':
                navigate('/postreg');
                break;
            case '/postreg2':
                navigate('/postreg2');
                break;
            case '/dash':
                navigate('/dash');
                break;
            case '/step5':
                navigate('/dash');
                break;
            case '/step55':
                navigate('/step5');
                break;
            case '/profile':
                navigate('/myStations');
                break;
            default:
                navigate(-1);
        }
    };

    const handleGoUserProfile = () => {
        navigate("/profile");
    };

    console.log(location.pathname);

    const handleGoChat = () => {
        const isFeatureReady = false; // Пока функционал не реализован
        if (!isFeatureReady) return; // Если функционал недоступен, ничего не делаем
        navigate("/chat");
    };

    const handleImageError = (event) => {
        event.target.src = defaultAvatar;
    };

    // Список страниц, на которых кнопки не должны отображаться
    const hideBackButtonPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/postreg', '/dash', '/regform', '/connectComplete', '/myStations'];
    const hideAvatarPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/regform', '/preprofile'];
    // const hideHeader = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/start', '/regform'];
    const hideProfile = ['/profile', '/editProfile'];

    // Если текущий путь есть в hideHeader, не рендерим компонент
    // if (hideHeader.includes(location.pathname)) {
    //     return null;
    // }

    return (
        <div className={styles.header}>
            <div className={styles.container}>
                <div className={styles.backButton}>
                    {!hideBackButtonPages.includes(location.pathname) && (
                        <div className={styles.backArrow} onClick={handleBack}>
                            <img src={backArrow} alt="Назад" className={styles.backArrowImg} />
                        </div>
                    )}
                    {/* <div
                        className={styles.backArrow}
                        onClick={handleBack}
                    >
                        <img
                            src={backArrow}
                            alt="Аватар пользователя"
                            className={styles.backArrowImg}
                            onError={handleImageError}  // Обработка ошибки загрузки
                        />
                    </div> */}
                </div>

                <div className={styles.profileContainer}>

                    {!hideProfile.includes(location.pathname) && (
                        <div
                            className={styles.profile}
                            onClick={handleGoUserProfile}
                        >
                            <img
                                src={profile}
                                alt="Профиль пользователя"
                                className={styles.profileImg}
                                onError={handleImageError}
                            />
                        </div>
                    )}
                    <div
                        className={styles.chat}
                        onClick={handleGoChat}
                    >
                        <img
                            src={chat}
                            alt="Чат"
                            className={styles.chatImg}
                            onError={handleImageError}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Header;
