import React, { useEffect, useState } from 'react';
import styles from './MyStations.module.css';
import { checkToken, hasRole } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import plusIcon from "../../../img/plus-station.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ministation from "../../../img/Station.png";
import classNames from 'classnames';
import Header from '../../Header/Header';

const MyStations = () => {
    const [stations, setStations] = useState([]);
    const token = localStorage.getItem('m_token')
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => {
            setCurrentStation(stations[next]);
        },
    };
    const navigate = useNavigate();
    const [currentStation, setCurrentStation] = useState(null);

    // if (window.Telegram && window.Telegram.WebApp) {
    //     const viewportHeight = window.Telegram.WebApp.viewportStableHeight;
    //     toast.error('Ошибка ' + viewportHeight, {
    //         position: "top-center",
    //         autoClose: 10000
    //     });
    // }

    useEffect(() => {
        if (!checkToken(token)) {
            toast.error('Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });
            navigate('/step2');
        }
    }, [token, navigate]);

    const [isDeveloper, setDeveloper] = useState();
    useEffect(() => {
        if (hasRole(token, "ROLE_DEV")) {
            setDeveloper(true);
        } else {
            setDeveloper(false);
        }
    }, [token]);

    const changeStatusLanguege = (status, isAlive) => {
        switch (status) {
            case 'Preparing':
                if (isAlive) {
                    return 'Ожидание';
                } else {
                    return 'Нет связи'
                }
            case 'Available':
                if (isAlive) {
                    return 'Свободно';
                } else {
                    return 'Нет связи'
                }
            case 'Faulted':
                if (isAlive) {
                    return 'Ошибка';
                } else {
                    return 'Нет связи'
                }
            case 'Finishing':
                if (isAlive) {
                    return 'Завершено';
                } else {
                    return 'Нет связи'
                }
            case 'Charging':
                if (isAlive) {
                    return 'Зарядка';
                } else {
                    return 'Нет связи'
                }
            default:
                if (isAlive) {
                    return 'Статус';
                } else {
                    return 'Нет связи'
                }
        }
    };

    const getStyleStatus = (status, isAlive) => {
        if (!isAlive) {
            return styles.statusOff;
        }
        switch (status) {
            case 'Preparing':
                return styles.statusPreparing;
            case 'Available':
                return styles.status;
            case 'Faulted':
                return styles.statusFaulted;
            case 'Finishing':
                return styles.statusFinishing;
            case 'Charging':
                return styles.statusCharging;
            default:
                return styles.status;
        }
    };

    const getStyleAlive = (status) => {
        if (status) {
            return styles.card;
        } else {
            return styles.cardOff;
        }
    };

    const getStyleTypeAlive = (status) => {
        if (status) {
            return styles.paramContainer;
        } else {
            return styles.paramContainerOff;
        }
    };

    useEffect(() => {
        changeStatusLanguege(currentStation?.connectors[0]?.status, currentStation?.isAlive);
        getStyleStatus(currentStation?.connectors[0]?.status, currentStation?.isAlive);
        getStyleAlive(currentStation?.connectors[0]?.status);
        getStyleTypeAlive(currentStation?.connectors[0]?.status);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/list`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                // console.log(result)
                setStations(result);
                if (result.length > 0) {
                    setCurrentStation(result[0]);
                }
            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 10000);
        return () => clearInterval(intervalId);
    }, [token]);

    // console.log(stations);

    const handleConnect = () => {
        navigate('/connect');
    }

    const handleCardClick = (station) => {
        if (station.isAlive) {
            navigate('/stationControl', { state: { station } });
        } else {
            toast.error('Со станцией нет связи, проверьте интернет соединение', {
                position: "top-center",
                autoClose: 3000
            });
        }
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}>Мои станции</h1>
            </div>
            <div className={stations?.length === 0 ? styles.section : styles.hidden}>
                <p className={styles.mainText}>У вас пока нет добавленных станций, для подключения вам понадобится физический доступ к зарядной станции</p>
            </div>
            <div className={stations?.length > 0 ? styles.mainSection : styles.hidden}>
                {stations?.length > 1 ? (
                    <Slider {...sliderSettings}>
                        {stations?.map((station, index) => (
                            <div
                                key={index}
                                className={classNames(styles.card, getStyleAlive(station.isAlive))}
                                onClick={() => handleCardClick(station)}
                            >
                                <div className={styles.information}>
                                    <div className={classNames(styles.status, getStyleStatus(station?.connectors[0]?.status, station.isAlive))}>
                                        <p className={styles.paramText}>{changeStatusLanguege(station?.connectors[0]?.status, station.isAlive)}</p>
                                    </div>
                                    <div className={styles.parameters}>
                                        <div className={classNames(styles.paramContainer, getStyleTypeAlive(station.isAlive))}><p className={styles.paramText}>Type2</p></div>
                                        <div className={classNames(styles.paramContainer, getStyleTypeAlive(station.isAlive))}><p className={styles.paramText}>{station.powerCp}кВт</p></div>
                                    </div>
                                </div>
                                <div className={styles.imageContainer}>
                                    <img src={ministation} className={styles.miniStation} alt='ministation' />
                                </div>
                                <div className={styles.nickName}>
                                    <p className={styles.textStationName}>{station.nickName}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div
                        className={classNames(styles.card, getStyleAlive(stations[0]?.isAlive))}
                        onClick={() => handleCardClick(stations[0])}
                    >
                        <div className={styles.information}>
                            <div className={classNames(styles.status, getStyleStatus(stations[0]?.connectors[0]?.status, stations[0]?.isAlive))}>
                                <p className={styles.paramText}>{changeStatusLanguege(stations[0]?.connectors[0]?.status, stations[0]?.isAlive)}</p>
                            </div>
                            <div className={styles.parameters}>
                                <div
                                    className={classNames(styles.paramContainer, getStyleTypeAlive(stations[0]?.isAlive))}
                                ><p className={styles.paramText}>Type2</p></div>
                                <div className={classNames(styles.paramContainer, getStyleTypeAlive(stations[0]?.isAlive))}
                                ><p className={styles.paramText}>{stations[0]?.powerCp}кВт</p></div>
                            </div>
                        </div>
                        <div className={styles.imageContainer}>
                            <img src={ministation} className={styles.miniStation} alt='ministation' />
                        </div>
                        <div className={styles.nickName}>
                            <p className={styles.textStationName}>{stations[0]?.nickName}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.sectionButtons}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleConnect}
                        className={styles.submitButtonConnect}
                    >
                        <p className={styles.textButtons}>Добавить станцию</p><img src={plusIcon} className={styles.plusIcon} alt='plus' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MyStations;