import config from "../../../settings/config";
import {toast} from "react-toastify";

export const fetchProfile = async (token) => {
    try {
        const response = await fetch(`${config.app.host}/api/v1/user/getprofile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            toast.error('Response not ok', {
                position: 'top-center',
                autoClose: 3000,
            });
            return null;
        }

        const result = await response.json();
        return result;
    } catch (error) {
        toast.error('Response error: ' + error, {
            position: 'top-center',
            autoClose: 3000,
        });
        return null;
    }
}
