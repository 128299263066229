import React, {useEffect} from 'react';
import styles from './NStartScreen.module.css';
import {useNavigate} from "react-router-dom";
import { postEvent } from '@telegram-apps/sdk';

const NStartScreen = () => {
    const navigate = useNavigate();
    // postEvent('web_app_add_to_home_screen', { color_key: 'bg_color' });

 
    useEffect(() => {
            if (!localStorage.getItem('m_token') && localStorage.getItem("pin")) {
                /*toast.error('1-Сессия истекла, авторизируйтесь снова:', {
                    position: "top-center",
                    autoClose: 3000
                });*/
                navigate('/step2');
                return;
            } else if (localStorage.getItem("pin")) {
                navigate('/pin');    
            } else {
                // clear local storage (удаляем из localStorage всю информацию за исключением телефона)
                for (let i = 0, len = localStorage.length; i < len; ++i) {
                    if (localStorage.key(i) === 'phone') {
                        continue;
                    }
                    if (localStorage.key(i) === 'vkloginprocess') {
                        continue;
                    }
                    if (localStorage.key(i) === 'pin') {
                        continue;
                    }
                    localStorage.removeItem(localStorage.key(i));
                }
                navigate('/start');
            }
        }, []);

    const handleBegin = () => {
        navigate('/step2');
    }

    return (
        <div className={styles.container}>
            <div className={styles.imageWrapper}>
                {/* Добавьте изображение через CSS background */}
            </div>
            <div className={styles.content}>
                <h1 className={styles.title}>PSS</h1>
                <p className={styles.description}>
                    Откройте для себя простой и быстрый доступ к зарядным станциям по всему миру
                </p>
                <button className={styles.startButton} onClick={handleBegin}>
                    <p className={styles.textButton}>Начать</p>
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
};

export default NStartScreen;
