import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './ProfileEdit.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import CustomModal from '../../Utils/CustomModal';
import Header from '../../Header/Header';

const ProfileEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('m_token');
    const [user, setUser] = useState(location.state?.user);
    const [name, setName] = useState(user?.name);
    const [email, setEmail] = useState(user?.email);
    const [birthday, setBirthday] = useState(user?.birthday);
    const [brand, setBrand] = useState(user?.carBrand);
    const [model, setModel] = useState(user?.carModel);
    const [showModal, setShowModal] = useState(false);
    const userData = {
        name: name, // Имя пользователя
        email: email, // Электронная почта
        birthday: birthday, // Дата рождения
        carBrand: brand, // Марка автомобиля
        carModel: model, // Модель автомобиля
    };

    // console.log(user);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        birthday: '',
        carBrand: '',
        carModel: ''
    });

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, []);


    async function requestEditUser() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
                body: JSON.stringify(userData),
            });

            const responseData = await response.json();
            console.log(responseData)

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                console.log(errorMessage);
                toast.error('Произошла ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );

                
            } else {
                toast.success('Ваши данные успешно сохранены', {
                    position: "top-center",
                    autoClose: 3000
                }
                );

            }
            navigate('/profile');
        } catch (error) {
        }

    }

    // Обработка удаления профиля
    const confirmDeleteProfile = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    localStorage.clear();
                    window.location.href = '/'; // Перенаправляем на страницу входа
                }

            } catch (error) {
                localStorage.clear();
                window.location.href = '/'; // Перенаправляем на страницу входа
            }
            toast.success(`Профиль удалён!`, {
                position: "top-center",
                autoClose: 3000
            });
            localStorage.clear();
            window.location.href = '/'; // Перенаправляем на страницу входа
        }
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };

    // const formatDate = (dateString) => {
    //     const [year, month, day] = dateString.split('-');
    //     return `${day}.${month}.${year}`;
    // }; // Функция форматирования даты


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveSettings = () => {
        requestEditUser();
    }

    const handleLogOut = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>Настройки профиля</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Имя</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>E-mail</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Дата рождения</p>
                </div>
                <input
                    type="date"
                    className={styles.card}
                    value={formatDate(birthday)}
                    onChange={(e) => setBirthday(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Марка электромобиля</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Модель электромобиля</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                />

            </div>
            <div className={styles.sectionButtons}>
                {/* <button
                    type="button"
                    onClick={handleSettings}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>Привязать аккаунт <img
                        src={vklogo}
                        alt="VK logo"
                        className={styles.vkLogo}
                    /></p>
                </button> */}
                <button
                    type="button"
                    onClick={handleSaveSettings}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>Сохранить</p>
                </button>
                <button
                    type="button"
                    onClick={handleLogOut}
                    className={styles.submitDelete}
                >
                    <p className={styles.textButtons}>Удалить аккаунт</p>
                </button>
            </div>
            {/* <div className={styles.sectionButtonDelete}>
               
            </div> */}
            <CustomModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmDeleteProfile}
                textMsg={'Подтверждение удаления'}
                textQuestions={'Вы действительно хотите удалить аккаунт?'}
            />
        </div>
    )
}

export default ProfileEdit;