import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationControl.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import lightning from "../../../img/ligthning.svg"
import config from '../../../settings/config';
import { toast } from "react-toastify";
import schedule from "../../../img/schedule.svg"
import settings from "../../../img/settings.svg"
import phase from "../../../img/phase.svg"
import powerIcon from "../../../img/powerIcon.svg"
import timeIcon from "../../../img/timeIcon.svg"
import consumedEnergyIcon from "../../../img/consumedEnergyIcon.svg"
import infoIcon from "../../../img/info.svg"
import openIcon from "../../../img/openIcon.svg"
import classNames from 'classnames';
import { motion } from "framer-motion";
import Header from '../../Header/Header';

const StationControl = () => {
    let conLog = false;
    let hiddenInform = false;
    const navigate = useNavigate();
    const location = useLocation();
    const [station, setStation] = useState(location.state?.station);
    const [connector, setConnector] = useState(location.state?.station.connectors[0])
    const token = localStorage.getItem('m_token');
    const slaveId = 1;
    const [status, setStatus] = useState(connector?.status || location.state?.station.connectors[0]?.status);
    const [buttonText, setButtonText] = useState('Начать зарядку');
    const [backgroundNumber, setBackgroundNumber] = useState(0);
    const [timeTimer, setTime] = useState();
    const [finalTime, setFinalTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [readStatus, setReadStatus] = useState(true);
    const [isReadStatus, setIsReadStatus] = useState(false);
    const [fullConsumedEnergy, setFullConsumedEnergy] = useState(0);
    const [counter, setCounter] = useState(90);
    const [isRunningCounter, setIsRunningCounter] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAvailableStartButton, setAvailableStartButton] = useState(false);
    const [isPreparingStartButton, setPreparingStartButton] = useState(false);
    const [isFinishingStartButton, setFinishingStartButton] = useState(false);
    const [initialTimeSet, setInitialTimeSet] = useState(false);
    const toggleModal = () => {
        setIsModalOpen((prev) => !prev);
    };
    const [outerCounter, setOuterCounter] = useState(location.state?.station.outerCounter || false);
    let workTimer = true;

    const fillPercentage = Math.min((connector?.pm / station?.powerCp) * 100, 100);

    useEffect(() => {
        let interval;
        if (isRunningCounter) {
            interval = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter > 0) {
                        return prevCounter - 1;
                    } else {
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isRunningCounter]);

    useEffect(() => {
        if (!initialTimeSet && connector?.chargingTime !== undefined && connector?.chargingTime !== 0) {
            setTime(connector?.chargingTime);
            setInitialTimeSet(true);
        }
    }, [connector?.chargingTime, initialTimeSet]);

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isRunning]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const formatAmperege = (amperage) => {
        const parseAmpere = (amperage * 1).toFixed(1);
        return parseAmpere;
    };

    const formatPower = (power) => {
        const parsePower = (power / 100).toFixed(2);
        return parsePower;
    };

    const formatPowerRound = (power) => {
        const parsePower = (power / 100).toFixed(1);
        if (parsePower > 10) {
            return Math.round(parsePower);
        }
        return parsePower;
    };

    const getCircleStyle = (status) => {
        switch (status) {
            case 'Faulted':
                return styles.circleFaulted;
            case 'Preparing':
                return styles.circlePreparing;
            case 'Charging':
                return styles.circleCharging;
            default:
                return styles.circle;
        }
    };
    const getInnerCircleStyle = (status) => {
        switch (status) {
            case 'Preparing':
                return styles.innercircleRotate;
            case 'Charging':
                return styles.innercircleRotate;
            case 'Faulted':
                return styles.innercircleRotate;
            default:
                return styles.innercircle;
        }
    };

    const getStyleButton = (status) => {
        switch (status) {
            case 'Preparing':
                if (isPreparingStartButton) {
                    return styles.holdStartCharging;
                } else if (isAvailableStartButton) {
                    return styles.holdStartCharging;
                } else {
                    if (station.autoCharge) {
                        return styles.holdStartCharging;
                    } else {
                        return styles.submitStartCharging;
                    }
                }
            case 'Available':
                if (isAvailableStartButton) {
                    return styles.holdStartCharging;
                } else {
                    if (station.autoCharge) {
                        return styles.holdStartCharging;
                    } else {
                        return styles.submitStartCharging;
                    }
                }
            case 'Faulted':
                return styles.holdStartCharging;
            case 'Finishing':
                if (isFinishingStartButton) {
                    return styles.holdStartCharging;
                } else {
                    return styles.submitStartCharging;
                }
            default:
                return styles.submitStartCharging;
        }
    };

    useEffect(() => {
        getCircleStyle(status);
        getInnerCircleStyle(status);
        getStyleButton(status);
        console.log(counter < 1);
        if (counter < 1) {
            setIsRunningCounter(false);
            setAvailableStartButton(false);
            setPreparingStartButton(false);
            setFinishingStartButton(false);
            setCounter(90);
        }
    }, []);

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, []);

    useEffect(() => {
        switch (status) {
            case 'Faulted':
                setButtonText('Восстановление коннектера');
                setBackgroundNumber('')
                setIsRunning(false)
                break;
            case 'Finishing':
                if (isFinishingStartButton) {
                    setButtonText(`Ожидание зарядки: ${counter}`);
                    break;
                } else {
                    setButtonText('Повтор зарядки');
                    setBackgroundNumber('');
                    break;
                }

            case 'Preparing':
                if (isPreparingStartButton) {
                    setButtonText(`Ожидание зарядки: ${counter}`);
                    setBackgroundNumber(counter);
                    break;
                } else if (isAvailableStartButton) {
                    setButtonText('Ожидание зарядки');
                    setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                    setIsRunning(false);
                    break;
                } else {
                    if (station.autoCharge) {
                        setButtonText('Автозарядка');
                        setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    } else {
                        setButtonText('Начать зарядку');
                        setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    }
                }
            case 'Charging':
                setButtonText('Остановить зарядку');
                setIsRunning(true);
                setIsRunningCounter(false);
                setAvailableStartButton(false);
                setPreparingStartButton(false);
                setFinishingStartButton(false);
                setCounter(90);
                setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                setIsReadStatus(true);
                break;
            case "Available":
                workTimer = true;
                setTime(0);
                if (isAvailableStartButton) {
                    setButtonText(`Вставьте коннектор: ${counter}`);
                    setBackgroundNumber(counter);
                    break;
                } else {
                    if (station.autoCharge) {
                        setButtonText('Автозарядка');
                        setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    } else {
                        setButtonText('Начать зарядку');
                        setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    }
                }
            default:
                setButtonText('Начать зарядку');
                setBackgroundNumber(Math.round(formatPower(connector.consumedEnergy)));
                setIsRunning(false);
                setTime(0);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/info?sn=${station.sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    // toast.error('Ошибка получения данных ' + response.status, {
                    //     position: "top-center",
                    //     autoClose: 3000
                    // });
                }

                if (!response.ok) {
                    const result = await response.json().catch(() => ({})); // Парсим JSON только если возможно
                    const errorMessage = result?.message || `Ошибка: ${response.status}`;

                    if (response.status === 400) {
                        console.warn(`Запрос отклонен сервером: ${errorMessage}`);
                        return; // Игнорируем дальнейшую обработку
                    }

                    console.error(`Ошибка получения данных: ${errorMessage}`);
                    return;
                }

                const result = await response.json();
                if (result.status === null) {
                    return;
                }

                if (conLog) {
                    console.log(result);
                }

                setStation(result);
                console.log(result)
                let connector = result?.connectors[0];
                setConnector(connector);
                setStatus(connector.status);
                // console.log(`powerCommon=${result.powerCommon} consumedEnergy=${result.consumedEnergy}`);

                if (connector.status === "Finishing") {
                    setFullConsumedEnergy(connector.consumedEnergy);
                    setFinalTime(connector.chargingTime);
                }
                if (counter < 1) {
                    setIsRunningCounter(false);
                    setAvailableStartButton(false);
                    setPreparingStartButton(false);
                    setFinishingStartButton(false);
                    setCounter(90);
                }
            } catch (error) {
            } finally {
                if (counter < 1) {
                    setIsRunningCounter(false);
                    setAvailableStartButton(false);
                    setPreparingStartButton(false);
                    setFinishingStartButton(false);
                    setCounter(90);
                }
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [readStatus, fullConsumedEnergy, isRunningCounter, isAvailableStartButton, isPreparingStartButton, counter, finalTime]);

    async function handleButton() {
        switch (status) {
            case 'Preparing':
                if (isPreparingStartButton) {
                    handleHidden();
                    break;
                } else if (isAvailableStartButton) {
                    handleHidden();
                    break;
                } else {
                    if (station.autoCharge) {
                        toast.success("Включена автозарядка, если хотите сделать старт из приложения выключите автозарядку в настройках станции", {
                            position: "top-center",
                            autoClose: 5000
                        });
                        break;
                    } else {
                        requestStartCharging();
                        toast.success("Запуск зарядки", {
                            position: "top-center",
                            autoClose: 3000
                        });
                        break;
                    }
                }
            case 'Charging':
                console.log('Stop')
                requestStopCharging();
                toast.success("Остановка зарядки, пожалуйста подождите", {
                    position: "top-center",
                    autoClose: 3000
                });
                break;
            case 'Faulted':
                console.log('Faulted')
                handleHidden();
                break;
            case 'Finishing':
                console.log('Finish')
                if (isFinishingStartButton) {
                    handleHidden();
                    toast.success("Подождите пока зарядка запустится", {
                        position: "top-center",
                        autoClose: 3000
                    });
                } else {
                    requestStartCharging();
                    toast.success("Запуск зарядки", {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                break;
            case 'Available':
                if (isAvailableStartButton) {
                    handleHidden();
                } else {
                    if (station.autoCharge) {
                        toast.success("Включена автозарядка, если хотите сделать старт из приложения выключите автозарядку в настройках станции", {
                            position: "top-center",
                            autoClose: 5000
                        });
                    } else {
                        requestStartCharging();
                        toast.success("Подключите коннектор и зарядка запустится", {
                            position: "top-center",
                            autoClose: 3000
                        });
                    }
                }
                break;
            default:
                console.log('Start');
                requestStartCharging();
        }
    }

    async function requestStartCharging() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/station/start?sn=${station.sn}&maxP=1&maxC=1&slaveId=${slaveId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            console.log(responseData)

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                console.log(errorMessage);
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
        if (status === "Available") {
            setAvailableStartButton(true);
        }
        if (status === "Preparing") {
            setPreparingStartButton(true);
        }
        if (status === "Finishing") {
            setFinishingStartButton(true);
        }
        setIsRunningCounter(true);
    }

    async function requestStopCharging() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/station/stop?sn=${station.sn}&slaveId=${slaveId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                console.log(errorMessage);
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    const handleHidden = () => {
    }

    const handleFinishing = () => {
        setReadStatus(true);
    }

    const handleSchedule = () => {
        // navigate('/stationSchedule', { state: { station } });
        if (!isAvailableStartButton && !isPreparingStartButton) {
            navigate('/stationSchedule', { state: { station } });
        }

    }

    const handleSettings = () => {
        if (!isAvailableStartButton && !isPreparingStartButton) {
            navigate('/stationSettings', { state: { station } });
        }
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <h1 className={styles.titleText}>{station?.nickName}</h1>
                <div
                    className={styles.settingsContainer}
                    onClick={handleSettings}
                >
                    <img src={settings} className={styles.imgSettings} alt='settings' />
                </div>
            </div>
            <div className={styles.main}>
                {status === "Finishing" && (
                    <div className={styles.finishingSection}>

                        <div className={styles.sectionTitle}>
                            <p>Зарядка закончена, предоставляем Вам <br /> данные о зарядной сессии</p>
                        </div>
                        <div className={styles.chargingInfoContainer}>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>Время зарядки</p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>{formatTime(connector.chargingTime)}</p>
                                </div>
                            </div>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>Заряжено</p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>{formatPower(connector.consumedEnergy)} кВтч</p>
                                </div>
                            </div>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>Запас хода <img
                                        src={infoIcon}
                                        className={styles.imgSettings}
                                        alt='info'
                                        onClick={toggleModal}
                                    /></p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>{((Math.round(formatPower(connector.consumedEnergy)) / 22) * 100).toFixed(1)} км</p>
                                </div>
                            </div>
                        </div>
                        {/* Модальное окно */}
                        {isModalOpen && (
                            <div className={styles.modalOverlay} onClick={toggleModal}>
                                <div
                                    className={styles.modalContent}
                                    onClick={(e) => e.stopPropagation()} // Отключаем закрытие по клику внутри модалки
                                >
                                    <p className={styles.modalText}>Информация о запасе хода</p>
                                    <p className={styles.modalText}>
                                        Запас хода рассчитывается исходя из заряженной энергии и средней
                                        эффективности электромобиля (22 кВт·ч на 100 км).
                                    </p>
                                    <button className={styles.closeButton} onClick={toggleModal}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {status === "Faulted" && (
                    <div className={styles.faultedSection}>
                        <div className={styles.faulted}>
                            <p>В процессе зарядной сессии произошла ошибка:<br /> {connector.error}</p>
                            <p>Пожалуйста подождите, коннктор восстановится <br />через несколько минут.<br /></p>
                        </div>
                    </div>)}
                {status !== "Finishing" && status !== "Faulted" && (
                    <div className={styles.section}>
                        <div className={styles.parameters}>
                            <div className={styles.mainParameters}>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase A' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Фаза А</p>
                                        <p className={styles.paramText}>{formatAmperege(connector.currentA)} A / {connector.voltageA} B</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase B' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Фаза B</p>
                                        <p className={styles.paramText}>{formatAmperege(connector.currentB)} A / {connector.voltageB} B</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase C' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Фаза C</p>
                                        <p className={styles.paramText}>{formatAmperege(connector.currentC)} A / {connector.voltageC} B</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.mainParameters}>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={powerIcon} className={styles.paramImage} alt='power' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Мощность</p>
                                        <p className={styles.paramText}>{connector.powerCommon} кBт
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={timeIcon} className={styles.paramImage} alt='time' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Время</p>
                                        <p className={styles.paramText}>{formatTime(timeTimer)}</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={consumedEnergyIcon} className={styles.paramImage} alt='consumedEnergy' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>Потребление</p>
                                        <p className={styles.paramText}>{formatPower(connector.consumedEnergy)} кBтч</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {outerCounter && (
                            <div className={styles.innerCounterContainer}>
                                <div className={styles.innerCounterName}>
                                    <p className={styles.innerCounterText}>Внешний счётчик <img src={openIcon} className={styles.paramImage} alt='open' /></p>
                                </div>
                                <div className={styles.innerData}>
                                    <div className={styles.mainParameters}>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase A' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>Фаза А</p>
                                                <p className={styles.paramText}>{connector?.iam} A / {connector?.uam} B / {connector?.pam} кВт</p>
                                            </div>
                                        </div>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase B' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>Фаза B</p>
                                                <p className={styles.paramText}>{connector?.ibm} A / {connector?.ubm} B / {connector?.pbm} кВт</p>
                                            </div>
                                        </div>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase C' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>Фаза C</p>
                                                <p className={styles.paramText}>{connector?.icm} A / {connector?.ucm} B / {connector?.pcm} кВт</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.innerBattery}>
                                        <div className={styles.powerCard}>
                                            <span className={styles.title}>Мощность</span>
                                            <div className={styles.innerContainer}>
                                                <motion.div
                                                    className={styles.fill}
                                                    style={{ height: `${fillPercentage}%` }}
                                                    initial={{ height: 0 }}
                                                    animate={{ height: `${fillPercentage}%` }}
                                                    transition={{ duration: 1 }}
                                                />
                                                <div className={styles.powerText}>
                                                    {connector?.pm}
                                                    <span className={styles.unit}>kW</span>
                                                </div>

                                                {/* <p className={styles.innerBatteryText}>Мощность</p>
                                            <div className={styles.innerBatteryBody}>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!outerCounter && (
                            <div
                                class={classNames(styles.circle, getCircleStyle(status))}>
                                <div class={classNames(styles.innercircle, getInnerCircleStyle(status))}>
                                    <span class={styles.text}>{formatPowerRound(connector.consumedEnergy)}<br />kWh</span>
                                </div>
                            </div>

                        )}
                    </div>
                )}
            </div>

            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleButton}
                    className={classNames(styles.submitStartCharging, getStyleButton(status))}
                >
                    <p className={styles.textButtons}>{buttonText}</p><img src={lightning} className={styles.lightning} alt='lightning' />
                </button>
                <button
                    type="button"
                    onClick={handleSchedule}
                    className={styles.submitSchedule}
                >
                    <p className={styles.textButtons}></p><img src={schedule} className={styles.scheduleImg} alt='schedule' />
                </button>
            </div>
            <div class={styles.backgroundNumber}>{backgroundNumber}</div>
        </div>
    );
}

export default StationControl;