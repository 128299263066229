import React, { useEffect, useState } from 'react';
import styles from './RegistrationForm.module.css';
import config from "../../../settings/config"
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import leoProfanity from "leo-profanity";
import { toast } from "react-toastify";


leoProfanity.loadDictionary("ru");

const nameSchema = yup.string()
    .matches(/^[A-ZА-ЯЁ][a-zа-яё]+ [A-ZА-ЯЁ][a-zа-яё]+$/, "Введите имя и фамилию с заглавной буквы")
    .max(35, "Имя и фамилия должны содержать не более 35 символов")
    .test("no-profanity", "Запрещённые слова недопустимы!", (value) => {
        return value ? !leoProfanity.check(value) : true;
    })
    .required("Поле обязательно для заполнения");

const emailSchema = yup.string()
    .email("Введите корректный email") // Проверка формата email
    .required("Email обязателен");


const birthdaySchema = yup.date()
    .min(new Date("1900-01-01"), "Дата не может быть раньше 01.01.1900")
    .max(new Date(), "Дата не может быть в будущем")
    .required("Введите дату рождения");

const RegistrationForm = () => {
    const groupedAutos = [
        {
            label: 'Leapmotor',
            code: 'Leapmotor',
            items: [
                { label: 'C01', value: 'Leapmotor С01' },
                { label: 'C11', value: 'Leapmotor С11' },
                { label: 'C16', value: 'Leapmotor С16' },
                { label: 'S01', value: 'Leapmotor S01' }
            ]
        },
        {
            label: 'Nissan',
            code: 'Nissan',
            items: [
                { label: 'Ariya', value: 'Nissan Ariya' },
                { label: 'LEAF', value: 'Nissan LEAF' },
                { label: 'NV200', value: 'Nissan NV200' }
            ]
        },
        {
            label: 'Tesla',
            code: 'Tesla',
            items: [
                { label: 'Cybertruck', value: 'Tesla Cybertruck' },
                { label: 'Model 3', value: 'Tesla Model S' },
                { label: 'Model X', value: 'Tesla Model X' },
                { label: 'Model Y', value: 'Tesla Model Y' }
            ]
        },
        {
            label: 'BMW',
            code: 'BMW',
            items: [
                { label: 'i3', value: 'BMW i3' },
                { label: 'iX', value: 'BMW iX' },
                { label: 'i4', value: 'BMW i4' }
            ]
        }
    ];

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState("");
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [errorBirthday, setErrorBirthday] = useState("");
    const [customCar, setCustomCar] = useState("");
    const [isCustom, setIsCustom] = useState(false);
    const navigate = useNavigate();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const token = localStorage.getItem('m_token');;

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
        if ((errorName === '' && name !== '') && (errorEmail === '' && email !== '') && errorBirthday === '') {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
        // console.log(`Errors: name=${(errorName === '' && name !== '')}, email=${(errorEmail === '' && email !== '')} errorB=${errorBirthday === ''}`);
    }, [errorEmail, errorName, email, name, errorBirthday]);

    const handleChangeName = async (e) => {
        const value = e.target.value;
        setName(value);

        try {
            await nameSchema.validate(value);
            setErrorName("");
        } catch (err) {
            setErrorName(err.message);
        }
    };

    const handleChangeEmail = async (e) => {
        const value = e.target.value;
        setEmail(value);

        try {
            await emailSchema.validate(value);
            setErrorEmail("");
        } catch (err) {
            setErrorEmail(err.message);
        }
    };

    const handleChangeBirthday = async (e) => {
        const value = e.target.value;
        setBirthday(value);

        try {
            await birthdaySchema.validate(new Date(value));
            setErrorBirthday("");
        } catch (err) {
            setErrorBirthday(err.message);
        }
    };

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "custom") {
            setIsCustom(true);
            setCustomCar(""); // Очистка поля для ручного ввода
        } else {
            setIsCustom(false);
            setCustomCar(selectedValue);
        }
    };

    console.log(customCar);

    const handleChange = (e) => {
    };

    const handleSubmit = (e) => {
        if (!isButtonEnabled) {
            return;
        }
        e.preventDefault(); // Предотвращаем перезагрузку страницы
        requestSettings();
    };

    async function requestSettings() {
        try {
            let carBrand1 = '';
            let carModel1 = '';
            const customCarSplit = customCar.split(' ');
            // Создаем объект с данными для отправки
            if (customCarSplit.length > 1) {
                carBrand1 = customCarSplit[0];
                carModel1 = customCarSplit[1];
            } else {
                carBrand1 = customCar;
            }

            // console.log(`car=${carBrand1} model=${carModel1}`);

            const requestBody = {
                name: name,
                email: email,
                birthday: birthday,
                carBrand: carBrand1,
                carModel: carModel1,
            };

            const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                method: 'POST', // Используем метод POST
                headers: {
                    'Content-Type': 'application/json', // Указываем, что отправляем JSON
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
                body: JSON.stringify(requestBody), // Преобразуем объект в JSON
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                console.log(errorMessage);
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
            toast.success('Данные сохранены!', {
                position: "top-center",
                autoClose: 3000
            });
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
        }
        navigate('/postreg', { state: { name: name } });
    }

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`; // Формат для отправки на сервер
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <h1 className={styles.titleText}>Создание аккаунта</h1>
            </div>
            <div className={styles.mainSection}>
                <p>Кажется, вы здесь впервые. Введите свои данные, <br /> чтобы получить персонализированный доступ к <br />зарядным станциям..</p>
                <p className={styles.requiredNote}>*Поля обязательные для заполнения</p>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Как Вас зовут?*</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={name}
                    onChange={handleChangeName}
                    placeholder="Иван Иванов"
                />
                {errorName && <p style={{ color: "red" }}>{errorName}</p>}
                <div className={styles.parameter}>
                    <p className={styles.paramText}>E-mail *</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder="test@ya.ru"
                />
                {errorEmail && <p style={{ color: "red" }}>{errorEmail}</p>}
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Дата рождения</p>
                </div>
                <input
                    type="date"
                    className={styles.card}
                    value={birthday}
                    onChange={handleChangeBirthday}
                />
                {errorBirthday && <p style={{ color: "red" }}>{errorBirthday}</p>}
                <div className={styles.formGroup}>
                    <label htmlFor="carModel">Марка и модель электромобиля</label>
                    <select
                        id="carModel"
                        name="carModel"
                        value={isCustom ? "custom" : customCar}
                        onChange={handleSelectChange}
                        className={styles.card}
                    >
                        <option value="">Не выбрано</option>
                        {groupedAutos.map((group) => (
                            <optgroup key={group.code} label={group.label}>
                                {group.items.map((car) => (
                                    <option key={car.value} value={car.value}>
                                        {car.value}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                        <option value="custom">Другое...</option>
                    </select>

                    {isCustom && (
                        <input
                            type="text"
                            placeholder="Введите марку и модель"
                            value={customCar}
                            className={styles.card}
                            onChange={(e) => {
                                const value = e.target.value;
                                setCustomCar(value);
                                handleChange({ target: { name: "carModel", value } });
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.submitWrapper}>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className={isButtonEnabled ? styles.submitButtonEnabled : styles.submitButtonDisabled}
                >
                    Далее
                    <span className={isButtonEnabled ? styles.arrowEnable : styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
};

export default RegistrationForm;
