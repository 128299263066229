import { AuthProvider } from "./context/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import RegistrationStep2 from "./component/MainRoad/Step2/RegistrationStep2";
import RegistrationStep3 from "./component/MainRoad/Step3/RegistrationStep3";
import { ToastContainer } from "react-toastify";
import PinCodeScreen from "./component/PinCodeScreen/InputPinCode/PinCodeScreen";
import PinCodeOffer from "./component/PinCodeScreen/OfferPin/Step1/PinCodeOffer";
import PinCodeOffer2 from "./component/PinCodeScreen/OfferPin/Step2/PinCodeOffer2";
import { useState, useEffect } from "react";
import NStartScreen from "./component/MainRoad/StartScreen/NStartScreen";
import RegistrationForm from "./component/MainRoad/RegistrationForm/RegistrationForm";
import PostRegScreen from "./component/MainRoad/PostRegScreen/PostRegScreen";
import PostRegScreen2 from "./component/MainRoad/PostRegScreen2/PostRegScreen2";
import Header from "./component/Header/Header";
import ConnectStation from "./component/MainRoad/ConnectStation/ConnectStation";
import ConnectStation2 from "./component/MainRoad/ConnectStation2/ConnectStation2";
import StationConnectComplete from "./component/MainRoad/StationConnectComplete/StationConnectComplete";
import MyStations from "./component/MainRoad/MyStations/MyStations";
import StationControl from "./component/MainRoad/StationControl/StationControl";
import StationSettings from "./component/MainRoad/StationSettings/StationSettings";
import StationSchedule from "./component/MainRoad/StationSchedule/StationSchedule";
import Profile from "./component/MainRoad/Profile/Profile";
import ProfileEdit from "./component/MainRoad/ProfileEdit/ProfileEdit";
import HistoryCharging from "./component/MainRoad/HistoryCharging/HistoryCharging";
import AboutApp from "./component/MainRoad/AboutApp/AboutApp";
import HelpPage from "./component/MainRoad/HelpPage/HelpPage";
import Language from "./component/MainRoad/Language/Language";
import StationScheduleEdit from "./component/MainRoad/StationSchedule/StationScheduleEdit";
import DeveloperSettings from "./component/MainRoad/DeveloperSettings/DeveloperSettings";
import CheckProfile from "./component/MainRoad/RegistrationForm/CheckProfile";

function App() {
    const [title, setTitle] = useState("PSS Charge"); // Состояние для заголовка

    return (
        <AuthProvider>
            <HashRouter>
                {/* <Header title={title} /> */}
                <Routes>
                    <Route path="/start" element={<NStartScreen setTitle={setTitle} />} />
                    <Route path="/step2" element={<RegistrationStep2 setTitle={setTitle} />} />
                    <Route path="/step3" element={<RegistrationStep3 setTitle={setTitle} />} />
                    <Route path="/pin" element={<PinCodeScreen setTitle={setTitle} />} />
                    <Route path="/pinoffer" element={<PinCodeOffer setTitle={setTitle} />} />
                    <Route path="/pinoffer2" element={<PinCodeOffer2 setTitle={setTitle} />} />
                    <Route path="/checkProfile" element={<CheckProfile/>} />
                    <Route path="/regform" element={<RegistrationForm />} />
                    <Route path="/postreg" element={<PostRegScreen />} />
                    <Route path="/postreg2" element={<PostRegScreen2 />} />
                    <Route path="/connect" element={<ConnectStation />} />
                    <Route path="/connect2" element={<ConnectStation2 />} />
                    <Route path="/connectComplete" element={<StationConnectComplete />} />
                    <Route path="/myStations" element={<MyStations />} />
                    <Route path="/stationControl" element={<StationControl />} />
                    <Route path="/stationSettings" element={<StationSettings />} />
                    <Route path="/stationSchedule" element={<StationSchedule />} />
                    <Route path="/stationScheduleEdit" element={<StationScheduleEdit />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/editProfile" element={<ProfileEdit />} />
                    <Route path="/history" element={<HistoryCharging />} />
                    <Route path="/about" element={<AboutApp />} />
                    <Route path="/help" element={<HelpPage />} />
                    <Route path="/language" element={<Language />} />
                    <Route path="/secret" element={<DeveloperSettings />} />
                    <Route path="*" element={<Navigate to="/start" replace />} />
                </Routes>
            </HashRouter>
            <ToastContainer hideProgressBar />
        </AuthProvider>
    );
}

export default App;
