import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkToken, hasRole } from "../../Utils/TokenService"; // Убедитесь, что пути к этим функциям правильные
import styles from "./DeveloperSettings.module.css"; // Убедитесь, что файл стилей существует
import config from '../../../settings/config';
import { toast } from "react-toastify";
import Header from "../../Header/Header";

const DeveloperSettings = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("m_token");
    const [isDeveloper, setDeveloper] = useState(false);
    const [manualInput, setManualInput] = useState("");

    useEffect(() => {
        if (!checkToken(token)) {
            navigate("/step2");
        } else if (hasRole(token, "ROLE_DEV")) {
            setDeveloper(true);
        } else {
            setDeveloper(false);
        }
    }, [token, navigate]);

    async function requestResetStations() {
            try {
                const response = await fetch(`${config.app.host}/api/v1/dev/reset?sn=${manualInput}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                    },
                });
    
                const responseData = await response.json();
    
                if (!response.ok) {
                    const errorMessage = responseData?.message || 'Неизвестная ошибка';
                    console.log(errorMessage);
                    // toast.error('Ошибка: ' + errorMessage, {
                    //     position: "top-center",
                    //     autoClose: 3000
                    // }
                    // );
                }
            } catch (error) {
            }
        }

    const handleManualSubmit = () => {
        if (manualInput.length >= 9) {
            requestResetStations();
            console.log("Запрос на перезагрузку отправлен", manualInput);
        } else {
            toast.error('Номер станции должен быть больше 8 символов', {
                    position: "top-center",
                    autoClose: 3000
            })
        }
    };

    return (
        <div className={styles.container}>
            <Header />
            {isDeveloper && (
                <div className={styles.mainSection}>
                    <div className={styles.sectionTitle}>
                        <p className={styles.titleText}>Для разработчиков</p>
                    </div>
                    <p>Серийный номер станции</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="text"
                            placeholder="Введите номер станции"
                            value={manualInput}
                            onChange={(e) => setManualInput(e.target.value)}
                            className={styles.manualInput}
                        />
                        <button
                            type="button"
                            onClick={handleManualSubmit}
                            className={manualInput.length >= 9 ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                            disabled={manualInput.length < 9}
                        >
                            <p className={styles.textButtons}>Reset</p>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeveloperSettings;