import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationSettings.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import CustomModal from '../../Utils/CustomModal';
import classNames from 'classnames';


const StationSettings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [station, setStation] = useState(location.state?.station);
    const [stationName, setStationName] = useState(location.state?.station.nickName);
    const [stationPower, setStationPower] = useState(location.state?.station.powerCp);
    const [showModal, setShowModal] = useState(false);
    const [maxPower, setMaxPower] = useState(location.state?.station.maxPower);
    const [autoCharge, setAutoCharge] = useState(location.state?.station.autoCharge || false);
    const [outerCounter, setOuterCounter] = useState(location.state?.station.outerCounter || false);
    const [isNotification, setIsNotification] = useState(location.state?.station.isNotification || false);

    const changeScale = (maxPower) => {
        if (maxPower > 100) return 10;
        if (maxPower > 20 && maxPower <= 100) return 5;
        if (maxPower <= 20) return 2;
        return 5;
    };

    const getStyleButton = (status) => {
        if (status) {
            return styles.autoChargeSettingOn;
        } else {
            return styles.autoChargeSetting;
        }
    }

    const scaleStep = changeScale(stationPower);
    // const station = location.state?.station;  // Получаем объект станции из state
    const token = localStorage.getItem('m_token');
    const slaveId = 1;

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
        getStyleButton(autoCharge);
    }, []);

    async function requestSettings() {
        try {
            // Создаем объект с данными для отправки
            const requestBody = {
                sn: station.sn, 
                nickName: stationName, 
                maxPower: maxPower, 
                slaveId: slaveId, 
                autoCharge: autoCharge,
                outerCounter: outerCounter,
                isNotification: isNotification,
            };

            const response = await fetch(`${config.app.host}/api/v1/station/settings/edit`, {
                method: 'POST', // Используем метод POST
                headers: {
                    'Content-Type': 'application/json', // Указываем, что отправляем JSON
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
                body: JSON.stringify(requestBody), // Преобразуем объект в JSON
            });

            const responseData = await response.json();
            console.log(responseData);

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                console.log(errorMessage);
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
            toast.success('Настройки станции сохранены!', {
                    position: "top-center",
                    autoClose: 3000
                });
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
        }
        navigate('/myStations');
    }

    const confirmDeleteStation = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/delete/?sn=${station.sn}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    navigate('/myStations'); // Перенаправляем на страницу входа
                }

            } catch (error) {
                navigate('/myStations'); // Перенаправляем на страницу входа
            }
            toast.success(`Станция ${station.nickName} удалена!`, {
                position: "top-center",
                autoClose: 3000
            });   
        }
        navigate('/myStations'); // Перенаправляем на страницу входа
    };

    const handleChangeAutoCharge = () => {
        setAutoCharge(!autoCharge);
    }

    const handleChangeInnerCounter = () => {
        setOuterCounter(!outerCounter);
    }

    const handleChangeIsNotification = () => {
        setIsNotification(!isNotification);
    }

    const handleSettings = () => {
        requestSettings();
    }

    const handleLogOut = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>Настройки станции</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Название станции</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={stationName}
                    onChange={(e) => setStationName(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>Максимальная мощность</p>
                </div>
                <div className={styles.maxPowerContainer}>
                    <div className={styles.sliderContainer}>
                        <input
                            type="range"
                            min="1"
                            max={stationPower}
                            step="1"
                            value={maxPower}
                            onChange={(e) => setMaxPower(e.target.value)}
                            className={styles.slider}
                            style={{
                                background: `linear-gradient(to right,rgb(80, 96, 110) ${(maxPower / stationPower) * 100}%, #ccc ${(maxPower / stationPower) * 100}%)`
                            }}
                        />
                        <div className={styles.scale}>
                            {[...Array(Math.ceil(stationPower / scaleStep) + 1)].map((_, index) => {
                                const value = index * scaleStep;
                                if (value > stationPower) return null; // Не добавляем деления выше максимальной мощности
                                return (
                                    <div key={value} className={styles.scaleMark}>
                                        <span className={styles.scaleLabel}>{value}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.currentValue}>
                        <p className={styles.textMaxPower}>{maxPower} кВт</p>
                    </div>
                </div>
                <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>Автозарядка</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(autoCharge))}
            
                        onClick={handleChangeAutoCharge}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div>
                <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>Отображение данных внешнего счётчика</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(outerCounter))}
            
                        onClick={handleChangeInnerCounter}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div>
                {/* <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>Уведомления в телеграмм</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(isNotification))}
            
                        onClick={handleChangeIsNotification}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div> */}
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleSettings}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>Сохранить</p>
                </button>
                <button
                    type="button"
                    onClick={handleLogOut}
                    className={styles.submitDelete}
                >
                    <p className={styles.textButtons}>Удалить станцию</p>
                </button>
            </div>
            <CustomModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmDeleteStation}
                textMsg={'Подтверждение удаления'}
                textQuestions={'Вы действительно хотите удалить станцию?'}
            />
        </div>
    );

}


export default StationSettings;