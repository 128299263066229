import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationSchedule.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import classNames from 'classnames';
import trash from "../../../img/trash.svg";
import { toast } from "react-toastify";
import Header from '../../Header/Header';


const StationSchedule = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [station, setStation] = useState(location.state?.station);
    const [infoSchedules, setInfoSchedules] = useState();
    // const station = location.state?.station;  // Получаем объект станции из state
    const token = localStorage.getItem('m_token');

    const getStyleButton = (autoCharge) => {
        if (autoCharge) {
            return styles.autoChargeSettingOn;
        } else {
            return styles.autoChargeSetting;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/schedule/${station.sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                // console.log(result)
                setInfoSchedules(result);
            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
    }, [token]);

    const deleteSchedule = async (index) => {
        console.log(`Object before delete:`, infoSchedules[index]); 
        const updatedSchedules = infoSchedules.filter((_, i) => i !== index);
        
        setInfoSchedules(updatedSchedules);

        console.log(updatedSchedules);
        console.log(`Object: ${infoSchedules[index]}`);

        try {
            const response = await fetch(`${config.app.host}/api/v1/schedule/${station.sn}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedSchedules),
            });

            if (!response.ok) {
                const errorMessage = (await response.json())?.message || 'Ошибка при сохранении расписания';
                throw new Error(errorMessage);
            }

            toast.success('Расписание успешно сохранено', {
                position: "top-center",
                autoClose: 2500,
            });
            
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000,
            });
        }

        navigate(0);
    };

    const changeSchedule = async (index) => {
        const updatedSchedules = infoSchedules.map((schedule, i) => 
            i === index ? { ...schedule, active: !schedule.active } : schedule
        );
    
        setInfoSchedules(updatedSchedules);
        try {
            const response = await fetch(`${config.app.host}/api/v1/schedule/${station.sn}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedSchedules),
            });

            if (!response.ok) {
                const errorMessage = (await response.json())?.message || 'Ошибка при сохранении расписания';
                throw new Error(errorMessage);
            }

            toast.success('Расписание успешно сохранено', {
                position: "top-center",
                autoClose: 2500,
            });
            navigate(0);
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000,
            });
        }
    };

    const handleChangeSchedule = (index) => {
        changeSchedule(index);
    }

    const handleDeleteSchedule = (index) => {
        deleteSchedule(index);
    }

    const addSchedule = () => {
        navigate('/stationScheduleEdit', { state: { station, infoSchedules } });
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>Расписание зарядных сессий</p>
            </div>
            <div className={styles.mainSection}>
                {infoSchedules?.map((schedule, index) => (
                     <div key={schedule.id || index} className={styles.scheduleContainer}>
                        <img
                            src={trash}
                            alt="trash"
                            className={styles.trashIcon}
                            onClick={() => handleDeleteSchedule(index)}
                        />
                        <div className={styles.card}>
                            <p className={styles.textAutoCharge}>{schedule.timeOn} - {schedule.timeOff}</p>
                            <div
                                class={classNames(styles.autoChargeSetting, getStyleButton(schedule.active))}

                                onClick={() => handleChangeSchedule(index)}
                            >
                                <div className={styles.autoChargeSettingElement}></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={addSchedule}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>Добавить расписание</p>
                </button>
            </div>
        </div>
    );
}

export default StationSchedule;