import React from 'react';
import styles from './CustomModal.module.css'; // Стили для модального окна

const CustomModal = ({ show, onClose, onConfirm, textMsg, textQuestions }) => {
    if (!show) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>{textMsg}</h2>
                <p>{textQuestions}</p>
                <div className={styles.modalButtons}>
                    <button className={styles.cancelButton} onClick={onClose}>
                        Нет
                    </button>
                    <button className={styles.confirmButton} onClick={onConfirm}>
                        Да
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;