import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './StationScheduleEdit.module.css';
import { checkToken } from "../../Utils/TokenService";
import config from '../../../settings/config';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import Header from '../../Header/Header';

const StationScheduleEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [station, setStation] = useState(location.state?.station);
    const [schedules, setSchedules] = useState(location.state?.infoSchedules);
    const token = localStorage.getItem('m_token');
    const [startTime, setStartTime] = useState('');
    const [stopTime, setStopTime] = useState('');
    const [error, setError] = useState('');

    console.log(station);
    console.log(schedules);


    // Проверка токена при загрузке компонента
    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, [token, navigate]);

    // Валидация времени
    const validateTime = (time) => {
        if (time.length < 4) return false;

        const hours = parseInt(time.slice(0, 2), 10);
        const minutes = parseInt(time.slice(2, 4), 10);

        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            return false;
        }
        return true;
    };

    // Обработчик изменения времени начала
    const handleStartTimeChange = (time) => {
        setStartTime(time);
        if (time.length === 4 && !validateTime(time)) {
            setError('Некорректное время начала (формат: HHMM)');
        } else {
            setError('');
        }
    };

    // Обработчик изменения времени окончания
    const handleStopTimeChange = (time) => {
        setStopTime(time);
        if (time.length === 4 && !validateTime(time)) {
            setError('Некорректное время окончания (формат: HHMM)');
        } else {
            setError('');
        }
    };

    // Форматирование времени в HH:MM
    const formatTime = (time) => {
        if (time.length === 4) {
            return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
        }
        return time;
    };

    // Отправка данных на сервер
    const addSchedule = async () => {
        if (!validateTime(startTime) || !validateTime(stopTime)) {
            setError('Пожалуйста, введите корректное время');
            return;
        }

        const formattedStartTime = formatTime(startTime);
        const formattedStopTime = formatTime(stopTime);

        const newSchedule = {
            timeOn: formattedStartTime,
            timeOff: formattedStopTime,
            dayOfWeek: ["mo", "tu", "we", "th", "fr", "sa", "su"],
            maxConsume: "",
            maxPower: "",
            single: "false",
            active: "true",
            description: "bla-bla-bla"
        };

        const updatedSchedules = schedules ? [...schedules, newSchedule] : [newSchedule];

        console.log(JSON.stringify({
            updatedSchedules
        }))

        try {
            const response = await fetch(`${config.app.host}/api/v1/schedule/${station.sn}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedSchedules),
            });

            if (!response.ok) {
                const errorMessage = (await response.json())?.message || 'Ошибка при сохранении расписания';
                throw new Error(errorMessage);
            }

            toast.success('Расписание успешно сохранено', {
                position: "top-center",
                autoClose: 2500,
            });
            navigate(-1);
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000,
            });
        }
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>Добавление расписания</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.timeInputContainer}>
                    <label className={styles.timeLabel}>Время начала (ЧЧ:MM):</label>
                    <OtpInput
                        value={startTime}
                        onChange={handleStartTimeChange}
                        numInputs={4}
                        renderSeparator={(index) => (
                            index === 1 ? <span>:</span> : null // Добавляем ":" после второго символа
                        )}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                className={styles.otpInput}
                                type="number"
                                pattern="\d*"
                                inputMode="decimal"
                                autoFocus={index === 0}
                            />
                        )}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>

                <div className={styles.timeInputContainer}>
                    <label className={styles.timeLabel}>Время окончания (ЧЧ:MM):</label>
                    <OtpInput
                        value={stopTime}
                        onChange={handleStopTimeChange}
                        numInputs={4}
                        renderSeparator={(index) => (
                            index === 1 ? <span>:</span> : null // Добавляем ":" после второго символа
                        )}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                className={styles.otpInput}
                                type="number"
                                pattern="\d*"
                                inputMode="decimal"
                            />
                        )}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>

                {error && <p className={styles.errorText}>{error}</p>}
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={addSchedule}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>Сохранить</p>
                </button>
            </div>
        </div>
    );
};

export default StationScheduleEdit;