import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AboutApp.module.css';
import { checkToken } from "../../Utils/TokenService";


const AboutApp = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');

    useEffect(() => {
                if (!checkToken(token)) {
                    navigate('/step2');
                }
            }, []);


    return (
        <div className={styles.container}>
        <div className={styles.sectionTitle}>
            <p className={styles.titleText}>О приложении</p>
        </div>
    </div>
    );

}

export default AboutApp;