import React, { useEffect, useState } from 'react';
import styles from './HistoryCharging.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import lightingChargeIcon from "../../../img/lightingChargeIcon.svg"
import config from '../../../settings/config';
import { toast } from "react-toastify";


const HistoryCharging = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');
    const [chargeSessions, setChargeSessions] = useState([]);

    const formatTime = (time) => {
        const date = new Date(time);

        const options = {
            timeZone: 'Europe/Moscow', // Указываем временную зону
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        const formattedDate = date.toLocaleString('ru-RU', options);
        return formattedDate;
        // return amperage;
    };

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/history`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                console.log(result)
                setChargeSessions(result);

            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
        // const intervalId = setInterval(fetchData, 30000);
        // return () => clearInterval(intervalId);
    }, [token]);

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>История зарядных сессий</p>
            </div>
            <div className={styles.mainSection}>
                {chargeSessions.map((history, index) => (
                    <div key={index} className={styles.card}>
                        <div className={styles.stationName}>
                            <p className={styles.textParam}>{history.stationsName}</p>
                        </div>
                        <div className={styles.sessionDate}>
                            <p className={styles.textParam}>
                                {formatTime(history.startTime).split(',')[0]} <br/> {formatTime(history.startTime).split(',')[1]}
                                {/* {history.date.split(' ')[0]} <br /> {history.date.split(' ')[1]} */}
                            </p>
                        </div>
                        <div className={styles.chargingData}>
                            <p className={styles.textParam}>Заряжено: {((history.consumeEnergy) / 100).toFixed(2)} кВтч</p>
                            <p className={styles.textParam}>
                                Продолжительность: <br /> {Math.round((history.endTime - history.startTime) / 60000)} мин
                            </p>
                        </div>
                        <div className={styles.image}>
                            <img
                                src={lightingChargeIcon}
                                alt="lightingChargeIcon"
                                className={styles.chargeIcon}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.sectionButtons}></div>
        </div>



    );
}

export default HistoryCharging;

