import React, { useEffect, useState } from 'react';
import styles from './HelpPage.module.css';
import { checkToken } from "../../Utils/TokenService";
import tgIcon from '../../../img/tgLogo.png';
import phoneIcon from '../../../img/phoneIcon.png';
import emailIcon from '../../../img/emailIcon.png';
import { useNavigate } from 'react-router-dom';

const HelpPage = () => {
    const token = localStorage.getItem('m_token');
    const navigate = useNavigate();

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, []);

    // const handleTgBot = () => {
    //     window.open("https://t.me/psschebot", "_blank");
    // };
    const handleTgBot = () => {
        window.open("https://t.me/psschebot", "_blank");
    };

    const handlePhone = () => {
        window.location.href = "tel:88005006290";
    };

    const handleEmail = () => {
        window.location.href = "mailto:support@zs-pss.ru";
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>Помощь</p>
            </div>
            <div className={styles.mainSection}>
                <div
                    className={styles.card}
                    onClick={handleTgBot}
                >
                    <p className={styles.text}>
                        <img src={tgIcon} alt="tgIcon" className={styles.icon} />
                        Бот в телеграмм
                    </p>
                </div>
                <div
                    className={styles.card}
                    onClick={handlePhone}
                >
                    <p className={styles.text}>
                        <img src={phoneIcon} alt="phoneIcon" className={styles.icon} />
                        Позвонить
                    </p>
                </div>
                <div
                    className={styles.card}
                    onClick={handleEmail}
                >
                    <p className={styles.text}>
                        <img src={emailIcon} alt="emailIcon" className={styles.icon} />
                        Написать на электронную почту
                    </p>
                </div>

            </div>
        </div>
    );

}

export default HelpPage;