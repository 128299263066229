import { isJwtExpired } from "jwt-check-expiration";
// import {updateSkunsToken} from "../MainRoad/FetchServises/UpdSkunsTokenService";
import { jwtDecode }  from 'jwt-decode';

export const checkToken = (token) => {
    try {
        // console.log(!isJwtExpired(token))
        if (!isJwtExpired(token))
            return true
        else return false;

    } catch (error) {
        return true;
    }
}

export const getUserRoles = (token) => {
    try {
        if (!token) return [];

        const decoded = jwtDecode(token);
        return decoded.roles || []; // 'authorities' - ключ, содержащий роли пользователя

    } catch (error) {
        console.error("Ошибка декодирования токена:", error);
        return [];
    }
};

export const hasRole = (token, role) => {
    const roles = getUserRoles(token);
    return roles.includes(role);
};