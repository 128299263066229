import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer2.module.css'
import OtpInput from 'react-otp-input';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "../../../MainRoad/HelpIcon/HelpIcon";
import arrow from "../../../../img/arrow-sm-right.svg";

const PinCodeOffer2 = () => {
    const [otp, setOtp] = useState('');
    const { state } = useLocation();
    const navigate = useNavigate();
    const [complite, setComplite] = useState(false)

    // console.log(state)

    const handleSubmit = () => {
        navigate('/checkProfile');
    };

    const handleBack = () => {
        navigate('/pinoffer');
    };

    function handlePinComplite(data) {
        if (data !== state.firstValues) {
            navigate('/pinoffer', { state: { error: true } });
        } else {
            setComplite(true);
            localStorage.setItem('pin', data);
        }
    }

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            console.log(state.firstValues)
            console.log(otp)
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    return (
        <div className={`${styles.container} ${complite ? styles.completedContainer : ''}`}>
            <div className={styles.section}>
                <div style={complite ? { display: 'none' } : null}>
                    <div className={`${styles.titleText} text-center`}>Повторите пароль:</div>
                </div>
            </div>
            <div className={styles.section}>
                <div style={complite ? { display: 'none' } : null}>
                    <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={4}
                        renderSeparator={<span></span>}
                        renderInput={(props, index) => <input
                            {...props}
                            className={styles.otpInput}
                            type="number"
                            pattern="\d*"
                            inputmode="decimal"
                            autoFocus={index === 0}
                        />}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>
                <div style={!complite ? { display: 'none' } : null} className={"text-center"}>
                    <div className={styles.complite}><p className={styles.messageText}>Пин-код установлен</p>
                    </div>
                </div>
            </div>
            <div className={complite ? styles.hidden : styles.sectionButtons}>
            {/* <div className={styles.sectionButtons}> */}
                <div className={styles.button}
                    onClick={handleBack}>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={styles.arrowIcon}
                    />
                    <p className={styles.buttonText}>Назад</p>
                </div>
            </div>
            <div className={!complite ? styles.hidden : styles.section}>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className={styles.submitButton}
                >
                    Продолжить
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
            <div className={!complite ? styles.section : styles.hidden}>
            </div>
        </div>
    );
}

export default PinCodeOffer2;