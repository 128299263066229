import React, { useEffect, useState } from 'react';
import styles from './ConnectStation2.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import QrScannerComponent from "../QrScannerComponent/QrScannerComponent";
import qrico from '../../../img/qrico.png';
import config from "../../../settings/config";
import mobileSignal from '../../../img/signal.svg';
import { toast } from "react-toastify";
import Header from '../../Header/Header';

const ConnectStation2 = () => {
    const [isScanning, setIsScanning] = useState(false);
    const [tempData, setTempData] = useState("");
    const [manualInput, setManualInput] = useState("");
    const [isManualInput, setIsManualInput] = useState(false);


    const navigate = useNavigate();
    // проверим токен для мобильного бэка
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const handleScan = (data) => {
        if (data && data.text && !isScanning) {
            const cleanedText = data.text.replace(/^http:\/\//, "");
            if (cleanedText.length >= 9 && (tempData !== cleanedText)) {
                setTempData(cleanedText);
                setIsScanning(true);
                getRequest(cleanedText).finally(setIsScanning(false));
            } else {
                console.log(cleanedText.length)
            }
        }
    };

    async function getRequest(data) {
        try {
            const groupid = localStorage.getItem('groupId');
            // Выполняем запрос с параметрами
            const response = await fetch(`${config.app.host}/api/v1/station/new?sn=${data}&groupId=${groupid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json(); // Переименовал переменную, чтобы избежать конфликта

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage,   {
                            position: "top-center",
                            autoClose: 3000
                        }
                );
                if (responseData.status === 400) {
                    console.log('YES')
                    navigate('/myStations')
                    return;
                }
            }

            navigate('/connectComplete');
        } catch (error) {
            // toast.error('Ошибка ' + error.text, {
            //     position: "top-center",
            //     autoClose: 3000
            // });
        }
    }

    const handleManualSubmit = () => {
        if (manualInput.length >= 9) {
            handleScan({ text: manualInput });
        } else {
            alert("Введите текст длиной не менее 9 символов");
        }
    };

    const handleManualInputChange = (e) => {
        setManualInput(e.target.value);
        setIsManualInput(e.target.value.length >= 1); // Когда пользователь начинает вводить текст
    };

    const handleError = (error) => {
        console.error("QR Scanner Error:", error);
    };

    const handleConnect = () => {
        navigate('/connect2');
    }

    const handleSkip = () => {
        navigate('/connect');
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}>Регистрация станции </h1>
                <p><br />Отсканируйте QR-code на Вашей станции</p>
                {!isManualInput && (
                    <div className={styles.camContainer}>
                        <QrScannerComponent onScan={handleScan} onError={handleError} />
                    </div>
                )}
            </div>
            <div className={styles.section}>
                <p><br />Или введите номер станции в ручную</p>
                <div className={styles.manualInputContainer}>
                    <input
                        type="text"
                        placeholder="Введите номер станции"
                        value={manualInput}
                        onChange={handleManualInputChange}
                        className={styles.manualInput}
                    />
                </div>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleManualSubmit}
                        className={
                            manualInput.length >= 9
                                ? styles.submitButtonConnect // Активный стиль
                                : styles.submitButtonConnectDis // Неактивный стиль
                        }
                        disabled={manualInput.length < 9}
                    >
                        <p className={styles.textButtons}>Подключить</p>
                        <img src={mobileSignal} className={styles.mobileSignal} alt='signal' />
                    </button>
                </div>
            </div>
            <div className={styles.section}></div>
            <div className={styles.sectionButton}>
                {/* <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleSkip}
                        className={styles.submitButtonSkip}
                    >
                        <p className={styles.textButtons}>Назад</p>
                    </button>
                </div> */}
                {/* <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleManualSubmit}
                        className={
                            manualInput.length >= 9
                                ? styles.submitButtonConnect // Активный стиль
                                : styles.submitButtonConnectDis // Неактивный стиль
                        }
                        disabled={manualInput.length < 9}
                    >
                       <p className={styles.textButtons}>Подключить</p>
                       <img src={mobileSignal} className={styles.mobileSignal} alt='signal' />
                    </button>
                </div> */}
            </div>
        </div>
    );

};

export default ConnectStation2;
