import React, { useEffect, useState } from 'react';
import styles from './StationConnectComplete.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import Header from '../../Header/Header';

const ConnectStationComplete = () => {

    const navigate = useNavigate();
    // проверим токен для мобильного бэка
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const handleConnect = () => {
        navigate('/myStations');
    }


    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}>Станция успешно подключена! </h1>
                <p><br />Теперь вы можете начать зарядку электромобиля,<br /> используя приложение</p>
            </div>
            <div className={styles.section}></div>
            <div className={styles.sectionButtons}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleConnect}
                        className={styles.submitButtonConnect}
                    >
                        <p className={styles.textButtons}>Мои станции</p>
                    </button>
                </div>
            </div>
        </div>

    );

}

export default ConnectStationComplete;